import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Layout } from '../components/common'

const CategoriesPage = () => {

    const result = useStaticQuery(graphql`
        {
            allGhostTag(sort: { order: ASC, fields: name }) {
                edges {
                    node {
                        slug
                        url
                        postCount
                    }
                }
            }
        }
    `)
    const tags = result.allGhostTag.edges

    return (
        <Layout>
            <div className="container">
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 className="content-title">Categories!</h1>
                    <section className="content-body">
                        !!!
                        {tags.forEach(({ node }) => {
                            { node.slug }
                        })}
                        !!!
                        
                        Page not found, <Link to="/">return home</Link> to start over
                    </section>
                </article>
            </div>
        </Layout>
    )
}

export default CategoriesPage
